import React from 'react'
import { Link } from 'gatsby'
// import github from '../img/github-icon.svg'
//import logo from '../img/logo.svg'
import logo from '../assets/logo/broadway-dynamic-auto-body-and-paint-logo-small.jpg'
// TODO: styled components remove or use
const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
            navBarActiveClass: 'is-active',
          })
          : this.setState({
            navBarActiveClass: '',
          })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
        style={{ background: '#003168', color: 'white' }}
      >
        <div className="container">
          <div className="navbar-brand" >
            <Link to="/" className="navbar-item" title="Logo" >
              {/* <img src={logo} alt="Kaldi" style={{ width: '88px' }} /> */}
              {/* <p style={{ color: "rgb(50, 115, 220)", fontWeight: "bold", fontSize: '20px' }}>Broadway Dynamic</p> */}
              <img src={logo} alt="Broadway Dynamic Auto Body & Paint" />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
              style={{ color: 'white' }}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item" to="/about" style={{ color: 'silver' }}>
                About
              </Link>
              {/* <Link className="navbar-item" to="/products">
                Products
              </Link>
              <Link className="navbar-item" to="/blog">
                Blog
              </Link> */}
              <Link className="navbar-item" to="/contact" style={{ color: 'silver' }}>
                Contact
              </Link>
              {/* <Link className="navbar-item" to="/contact/examples">
                Form Examples
              </Link> */}
            </div>
            {/* <div className="navbar-end has-text-centered">
              <a
                className="navbar-item"
                href="https://github.com/netlify-templates/gatsby-starter-netlify-cms"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <img src={github} alt="Github" />
                </span>
              </a>
            </div> */}
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
