import React from 'react'
import { Link } from 'gatsby'
import logo from '../assets/logo/broadway-dynamic-auto-body-and-paint-logo-footer.jpeg'
//import eco from '../assets/eco-friendly/environmentally-friendly-car-painting-service.png'
import eco from '../assets/eco-friendly/environmentally-friendly-car-painting-service-earth-smaller.png'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
// import twitter from '../img/social/twitter.svg'
// import vimeo from '../img/social/vimeo.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter" style={{ backgroundImage: 'linear-gradient(180deg, rgba(0,49,104,1) 50%, rgba(0,61,210,1) 100%)' }}>
        <div className="content has-text-centered" >
          <img src={logo} alt="Broadway Dynamic Auto Body & Paint" />


          <p style={{ color: "red", fontWeight: "bold", fontSize: '36px', fontWeight: "900" }}>Auto Body & Paint</p>

        </div>
        <div className="content has-text-centered has-text-white-ter">
          <div className="container  has-text-white-ter">


            <div className="container">
              <div className="columns" style={{ margin: '0' }}>
                <div className="column is-3">
                  <h3 style={{ color: '#808080' }}>Pages</h3>
                  <ul style={{ listStyle: 'none', marginLeft: '0' }}>
                    <li>
                      <Link to="/" className="navbar-item button">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item button" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item button" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>


                  {/* <li>
                      <a
                        className="navbar-item"
                        href="/admin/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Admin
                      </a>
                    </li> */}
                  {/* <li>
                      <Link className="navbar-item" to="/blog">
                        Latest Stories
                      </Link>
                    </li> */}


                </div>
                <div className="column is-3">
                  <h3 style={{ color: '#808080' }}>Contact</h3>
                  <a href="tel:+1-201-518-0942" className="navbar-item button">Call now</a>
                  <ul style={{ listStyle: 'none', marginLeft: '0', padding: '15px' }}>
                    <li >(201) 518-0942</li>
                    <li >(973) 980-7875</li>
                  </ul>
                </div>
                <div className="column is-3">



                  <h3 style={{ color: '#808080' }}>Address</h3>
                  <a className="navbar-item button" href="https://www.google.com/maps/dir//Broadway+Dynamic+Auto+Body+%26+Paint/data=!4m8!4m7!1m0!1m5!1m1!1s0x89c2f992f895ab81:0x6e4f54819488ad73!2m2!1d-74.056371!2d40.872" target="_blank" >Get directions</a>
                  <ul style={{ listStyle: 'none', marginLeft: '0', padding: '15px' }}>
                    <li>
                      <address style={{ fontStyle: 'normal' }}>
                        <div>256 Green Street</div>
                        <div>South Hackensack, NJ 07606</div>
                        <div>United States</div>
                      </address>
                    </li>
                  </ul>

                </div>

                <div className="column is-3 has-text-centered" style={{ margin: '0 auto', fontSize: '1em' }}>
                  <h3 style={{ color: '#808080' }}>Business Hours</h3>
                  <table >
                    <tbody >
                      <tr >
                        <th style={{ color: '#808080' }}>Mon:</th>
                        <td><span>8:00 AM – 5:00 PM</span></td>
                      </tr>
                      <tr>
                        <th style={{ color: '#808080' }}>Tue:</th><td><span>8:00 AM – 5:00 PM</span></td>
                      </tr>
                      <tr>
                        <th style={{ color: '#808080' }}>Wed:</th><td><span>8:00 AM – 5:00 PM</span></td>
                      </tr>
                      <tr>
                        <th style={{ color: '#808080' }}>Thu:</th><td><span>8:00 AM – 5:00 PM</span></td>
                      </tr>
                      <tr>
                        <th style={{ color: '#808080' }}>Fri:</th><td><span>8:00 AM – 5:00 PM</span></td>
                      </tr>
                      <tr>
                        <th style={{ color: '#808080' }}>Sat:</th><td><span>8:00 AM – 5:00 PM</span></td>
                      </tr>
                      <tr>
                        <th style={{ color: '#808080' }}>Sun:</th><td><span>Closed</span></td>
                      </tr>
                    </tbody>
                  </table>



                </div>
              </div>
              <div className="content has-text-centered" style={{ padding: '30px', fontSize: '14px' }}>


                <div className="social">
                  {/* h3 style={{ color: '#808080' }}>Social Media</h3> */}
                  <a title="facebook" href="https://www.facebook.com/Broadway-Dynamic-Auto-Body-and-Paint-265358720841657/">
                    <img
                      src={facebook}
                      alt="Facebook"
                      style={{ width: '1em', height: '1em' }}
                    />
                  </a>
                  {/* <a title="twitter" href="https://twitter.com">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a> */}
                  <a title="instagram" href="https://www.instagram.com/broadwaydynamic/">
                    <img
                      src={instagram}
                      alt="Instagram"
                      style={{ width: '1em', height: '1em' }}
                    />
                  </a>
                  {/* <a title="vimeo" href="https://vimeo.com">
                  <img
                    src={vimeo}
                    alt="Vimeo"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a> */}
                </div>
                <p>
                  <a href="mailto:broadwaydynamicauto@gmail.com" style={{ color: 'white', fontSize: '0.9em', letterSpacing: '0.18em' }}>
                    broadwaydynamicauto@gmail.com
                  </a>
                </p>
                <p><img src={eco} alt="Environmentally Friendly car painting service logo" /></p>
                <p style={{ color: 'gray' }}>Copyright © {new Date().getFullYear()} Broadway Dynamic Auto Body & Paint. All rights reserved.</p>

              </div>
            </div>
          </div>
        </div>

      </footer >
    )
  }
}

export default Footer
